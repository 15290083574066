export enum ApplicationTabs {
  Roles = 0,
  Profiles = 1,
}

export enum ApprovalStateEnum {
  AwaitingApproval = 0,
  Approved = 1,
  Rejected = 2,
}
